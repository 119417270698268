<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card light color="white">
      <v-card-title class="headline">{{ $t('common.dialog.warning') }}</v-card-title>
      <v-card-text class="pa-6">
        {{ $t('common.notification.fileAlreadyImported') }}
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer />
        <v-btn
          small
          text
          color="neuter"
          @click="closeDialog"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          small
          elevation="0"
          class="t-bw-primary--text"
          color="error"
          @click="confirmImport"
        >
          {{ $t('common.buttons.continue') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FileImportConfirmationDialog',
  
  props: {
    showConfirmDialog: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.showConfirmDialog
      },
      set(value) {
        if (!value) {
          this.$emit('cancel')
        }
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit('cancel')
    },

    confirmImport() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
.headline {
  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
}
</style>
