
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { ButtonConfig } from "@/types/types";

  @Component
  export default class ButtonGroupComponent extends Vue {
    @Prop({ default: () => [] }) buttons!: ButtonConfig[];

    menuStates: { [key: number]: boolean } = {};

    mounted() {
      this.buttons.forEach((button, index) => {
        if (button.type === 'menu') {
          this.$set(this.menuStates, index, false);
        }
      });
    }

    get visibleButtons(): ButtonConfig[] {
      return this.buttons.filter(button => this.shouldShowButton(button));
    }

    getButtonStyle(index: number): object {
      const visibleButtons = this.buttons.filter(button => this.shouldShowButton(button));
      const visibleIndex = visibleButtons.indexOf(this.buttons[index]);
      
      if (visibleIndex === -1) return {};
      
      if (visibleButtons.length === 1) {
        return { 'border-radius': '1.5em' };
      }
      
      if (visibleIndex === 0) {
        return { 'border-radius': '1.5em 0 0 1.5em' };
      } else if (visibleIndex === visibleButtons.length - 1) {
        return { 'border-radius': '0 1.5em 1.5em 0' };
      }
      return { 'border-radius': '0' };
    }

    closeMenu(index: number) {
      this.$set(this.menuStates, index, false);
    }

    shouldShowButton(button: ButtonConfig): boolean {
      return button.visible !== false;
    }
  }
