<template>
  <v-layout class="ma-0 pa-0">
    <v-list class="ma-0 pa-0">
      <v-list-item
        dense
        class="ma-0 pa-0"
      >
        <div class="d-flex justify-center align-center align-content-center">
          <v-btn v-for="(item, index) in moreOptionsItems" 
            :key="index" 
            small 
            text 
            @click="selectNomenclatorActions(item.action, items)" 
            :loading="item.action == 'EXPORT' && downloading"
            v-if="checkPermissions(item.action)"
          >
            <v-icon small color="neuter" left>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
        </div>
      </v-list-item>
    </v-list>
    
    <v-dialog v-model="showDialogImport" persistent width="600px">
      <v-card>
        <v-card-title class="headline secondary t-bw-secondary--text">{{ $t("common.titles.nomenclators.importDataTable") + " " +
        $t(`${stringTranslate}.fields.name`) + "(s)" }}
        </v-card-title>
        <v-card-text class="pa-6">
          <v-form ref="form" v-model="valid">
            <v-file-input
              outlined
              dense
              required class="required"
                          :rules="requireRulesSizeAndType(fileSelected)"
                          show-size
                          v-model="fileSelected" color="primary"
                          prepend-icon="mdi-paperclip"
                          :label="$t('documents.fields_add.file')"
                          small-chips
                          @change="changeFile()">
            </v-file-input>
          </v-form>
          <v-alert
            class="mt-2"
            border="left"
            color="orange"
            dense
            text
            type="warning"
            icon="mdi-alert-outline"
          >
            {{ $t("common.titles.warningUploadTemplate") }}
          </v-alert>

          <v-divider></v-divider>

          <v-list >
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title>{{ $t("common.titles.nomenclators.downloadTemplate") }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t("common.titles.uploadTemplateNote") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :loading="loadingT" small elevation="0" color="primary" class="t-bw-primary--text" @click="downloadTemplate">
                  {{ $t("common.buttons.generate") }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn small text color="neuter" @click="closeDialogImport()">
            {{ $t("common.buttons.cancel") }}
          </v-btn>
          <v-btn :loading="loading" small elevation="0" color="primary" class="t-bw-primary--text" :disabled="!valid" @click="importTemplate">
            {{ $t("common.buttons.import") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <file-import-confirmation-dialog 
      :show-confirm-dialog="showConfirmDialog" 
      @confirm="proceedWithImport"
      @cancel="cancelImport" 
    />
  </v-layout>
</template>

<script>
  import FileImportConfirmationDialog from '@/components/common/FileImportConfirmationDialog.vue'
  import DocumentsService from '@/services/DocumentsService'
  import PermisionService from '@/services/PermisionService'
  import i18n from "@/plugins/i18n"
  import LanguajeService from "../services/LanguajeService"
  import { mapActions, mapGetters } from "vuex"
  import ProductService from "../services/ProductService"
  import InputService from "../services/InputService"
  import SeasonsService from "../services/SeasonsService"
  import EquipmentService from "../services/EquipmentService"
  import OperatorService from "../services/OperatorService"
  import LocationService from "../services/Locations"
  import StorageService from "@/services/StorageService"
  import _ from "lodash"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    name: "MoreOptions",
    components: {
      FileImportConfirmationDialog
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      stringTranslate: {
        type: String,
        required: true,
      },
      stringTemplate: {
        type: String,
        required: false,
      },
      filters: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    data: () => ({
      valid: false,
      fileEditChange: false,
      fileSelected: null,
      showDialogImport: false,
      moreOptionsItems: [
        { title: i18n.t("common.buttons.import"), icon: "mdi-upload-multiple", action: "IMPORT" },
        { title: i18n.t("common.buttons.export"), icon: "mdi-download-multiple", action: "EXPORT" },
      ],
      loading: false,
      loadingT: false,
      downloading: false,
      showConfirmDialog: false,
    }),
    created() {
      this.profile = JSON.parse(localStorage.getItem("profile"))
    },
    computed: {
      ...mapGetters({
        requireRulesSizeAndType: "general/requireRulesSizeAndType",
      }),
      nomenclatorAlert: {
        get() {
          return this.$store.getters["ui/GET_NOMENCLATOR_DATA"]
        },
        set(val) {
          this.$store.commit("ui/NOMENCLATOR_DATA", val)
        },
      },
      notification: {
        get() {
          return this.$store.getters["ui/GET_NOTIFICATION"]
        },
        set(val) {
          this.$store.commit("ui/NOTIFICATION", val)
        },
      },
    },
    methods: {
      ...mapActions({
        fetchListInputs: "input/fetchListInputs",
        fetchListEquipment: "equipment/fetchListEquipment",
        fetchListOperator: "operator/fetchListOperator",
        fetchListSeasons: "season/fetchListSeasons",
        fetchListProduct: "product/fetchListProduct",
      }),
      alert(data) {
        let icon = data.importState === "ERRORED" || data.importState === "PARTIALLY" ? "mdi-alert-outline" : "mdi-check-all"
        let color = data.importState === "ERRORED" || data.importState === "PARTIALLY" ? "error" : "secondary"
        let message = data.importState === "ERRORED" || data.importState === "PARTIALLY" ? i18n.t("common.notification.importError") : i18n.t("common.notification.importSuccess")

        this.nomenclatorAlert.icon = icon
        this.nomenclatorAlert.color = color
        this.nomenclatorAlert.message = message
        this.nomenclatorAlert.import = data.itemsImported
        this.nomenclatorAlert.error = data.rowsFailed.length
        this.nomenclatorAlert.total = data.totalItems
        this.nomenclatorAlert.rowsFailed = data.rowsFailed

      },
      selectNomenclatorActions(type, items) {
        switch (type) {
          case "IMPORT":
            this.showDialogImport = true
            break
          case "EXPORT":
            this.exportSelectedItemsOrAll(items)
            break
        }
      },
      closeDialogImport() {
        this.showDialogImport = !this.showDialogImport
        this.fileSelected = null
        this.$refs.form.reset()
      },
      saveExcel(name, response, action) {
        const FileSaver = require("file-saver")
        const blob = new Blob([response.data], {
          type: "application/xlsx",
        })
        if (action === "export") {
          switch (name) {
            case "storage":
              name = this.$t("storage.label") + ".xlsx"
              break
            case "location":
              name = this.$t("locations.label") + ".xlsx"
              break
            default:
              name = name + "_data.xlsx"
              break
          }

          FileSaver.saveAs(blob, name)
        } else {
          switch (name) {
            case "storage":
              name = this.$t("storage.label") + ".xlsx"
              break
            case "location":
              name = this.$t("locations.label") + ".xlsx"
              break
            default:
              name = name + "_template.xlsx"
              break
          }
          FileSaver.saveAs(blob, name)
        }
      },
      async downloadTemplate() {
        this.loadingT = true
        let language = LanguajeService.getLenguajeName()
        switch (this.stringTemplate) {
          case "location":
            await LocationService.fetchTemplate({
              language: language,
            })
              .then((response) => {
                this.saveExcel("location", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "storage":
            await StorageService.fetchTemplate({
              language: language,
            })
              .then((response) => {
                this.saveExcel("storage", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "product":
            await ProductService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("product", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "season":
            await SeasonsService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("season", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "equipment":
            await EquipmentService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("equipment", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "input":
            await InputService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("input", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "operator":
            await OperatorService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("operator", response)
                this.$toast.success(i18n.t("common.notification.downloadTemplateSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.downloadTemplateError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
        }
      },
      async importTemplate() {
        if (!this.fileSelected) return
        this.loading = true

        try {
          const entityType = this.getEntityType()
          const { data } = await DocumentsService.validateImport(
            entityType,
            this.fileSelected
          )

          if (data.alreadyImported) {
            this.showConfirmDialog = true
            this.loading = false
            return
          }

          await this.proceedWithImport()
        } catch (error) {
          console.error(error)
          this.loading = false
          this.$toast.error(this.$t('common.notification.importError'))
        }
      },

      checkPermissions(action) {
        if (action) {
          if (action === 'EXPORT') {
            return this.checkAdmin() || this.check([{ domain: this.getEntityForPermissions(), permisions: ['Read'] }])
          }

          if (action === 'IMPORT') {
            return this.checkAdmin() || this.check([{ domain: this.getEntityForPermissions(), permisions: ['Import'] }])
          }
        }

        return false;
      },

      check(permisions) {
        return PermisionService.check(permisions);
      },

      checkAdmin() {
        return PermisionService.checkAdmin();
      },

      getEntityType() {
        const entityTypes = {
          'location': 'LOCATIONS',
          'storage': 'STORAGE_DEVICES',
          'product': 'PRODUCTS',
          'season': 'SEASONS',
          'equipment': 'EQUIPMENT',
          'input': 'INPUTS',
          'operator': 'OPERATORS'
        }
        return entityTypes[this.stringTemplate] || ''
      },

      getEntityForPermissions() {
        const entityTypes = {
          'location': 'Location',
          'storage': 'StorageDevice',
          'product': 'Culture',
          'season': 'Season',
          'equipment': 'Equipment',
          'input': 'Input',
          'operator': 'Operator'
        }
        return entityTypes[this.stringTemplate] || ''
      },

      async proceedWithImport() {
        this.loading = true
        try {
          let response
          const params = {
            language: LanguajeService.getLenguajeName(),
            file: this.fileSelected
          }

          switch (this.stringTemplate) {
            case 'location':
              response = await LocationService.import(params)
              break
            case 'storage':
              response = await StorageService.import(params)
              break
            case 'product':
              response = await ProductService.import(this.profile.company_id, this.fileSelected)
              break
            case 'season':
              response = await SeasonsService.import(this.profile.company_id, this.fileSelected)
              break
            case 'equipment':
              response = await EquipmentService.import(this.profile.company_id, this.fileSelected)
              break
            case 'input':
              response = await InputService.import(this.profile.company_id, this.fileSelected)
              break
            case 'operator':
              response = await OperatorService.import(this.profile.company_id, this.fileSelected)
              break
          }

          const data = response.data
          this.alert(data)
          this.notification = true
          await this.refreshData()
          this.closeDialogImport()
        } catch (error) {
          this.$toast.error(this.$t('common.notification.importError'))
        } finally {
          this.loading = false
          this.showConfirmDialog = false
        }
      },

      cancelImport() {
        this.loading = false
        this.fileSelected = null
        this.showConfirmDialog = false
      },

      refreshData() {
        switch (this.stringTemplate) {
          case 'product':
            this.fetchListProduct([{
              companyId: PROFILE.company_id,
              search: null,
              pageableDTO: {
                page: 0,
                size: 10,
                sortBy: "id",
                direction: "DESC",
              },
            }, this.$toast])
            break
          case 'season':
            this.fetchListSeasons([this.profile, this.$toast])
            break
          case 'equipment':
            this.fetchListEquipment([this.profile, this.$toast])
            break
          case 'input':
            this.fetchListInputs([this.profile, this.$toast])
            break
          case 'operator':
            this.fetchListOperator([this.profile, this.$toast])
            break
          case 'location':
          case 'storage':
            this.$root.$emit('setFiltersLocations')
            break
        }
      },
      exportSelectedItemsOrAll() {
        this.downloading = true
        let language = LanguajeService.getLenguajeName()
        switch (this.stringTemplate) {
          case "location":
            LocationService.exportCSV({
              language: language,
              versionIds: this.getByProperty(this.items, "id"),
              ...this.filters,
            })
              .then((response) => {
                this.saveExcel("location", response, "export", "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "storage":
            StorageService.exportCSV({
              language: language,
              versionIds: this.getByProperty(this.items, "id"),
              ...this.filters,
            })
              .then((response) => {
                this.saveExcel("storage", response, "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "product":
            ProductService.export(language)
              .then((response) => {
                this.saveExcel("product", response, "export", "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "season":
            SeasonsService.export(language)
              .then((response) => {
                this.saveExcel("season", response, "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "equipment":
            EquipmentService.export(language)
              .then((response) => {
                this.saveExcel("equipment", response, "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "input":
            InputService.export(language)
              .then((response) => {
                this.saveExcel("input", response, "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "operator":
            OperatorService.export(language)
              .then((response) => {
                this.saveExcel("operator", response, "export")
                this.$toast.success(i18n.t("common.notification.exportSuccess"), {
                  icon: "mdi-check-circle",
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("common.notification.exportError"), {
                  icon: "mdi-alert-circle",
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
        }
      },
      changeFile() {
        if (this.editedIndex > 0) {
          this.fileEditChange = true
        }
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },
    },
  }
</script>

<style scoped>
  .layout {
    display: contents !important;
  }

  .v-menu__content {
    border-radius: .5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    margin-top: 10px;
  }
</style>
